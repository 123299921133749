<template>
  <div class="p-4">
    <div class="text-center h4">Online Rezervasyon Sistemi v 2.0</div>

    <b-row>
      <b-col cols="12" md="4">
        <HomeWidget :show="show" :data="homeCardsValue[0]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeWidget :show="show" :data="homeCardsValue[1]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeWidget :show="show" :data="homeCardsValue[2]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeWidget :show="show" :data="homeCardsValue[3]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeWidget :show="show" :data="homeCardsValue[4]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeWidget :show="show" :data="homeCardsValue[5]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeWidget :show="show" :data="homeCardsValue[6]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeWidget :show="show" :data="homeCardsValue[7]" />
      </b-col>
      <b-col cols="12" md="4">
        <FirmaCard :show="show" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-card no-body>
          <b-overlay :show="show" variant="primary" spinner-type="grow" spinner-variant="primary" :opacity="0" blur="4px" rounded="sm">
            <b-card-header class="bg-transparent">
              <h5>Tarayıcı ve Cihaz Analizi</h5>
            </b-card-header>
            <b-card-text>
              <b-alert v-if="!Object.keys(tarayicilar).length || !cihazlar.length" show variant="warning" class="text-center m-2">
                <h5>Bilgi</h5>
                <hr />
                <p>Gösterilecek veri yok</p>
              </b-alert>
              <b-row v-else>
                <b-col cols="12" md="6">
                  <!-- tarayıcı -->
                  <DoughnutChartGenerate :data="tarayicilar" />
                </b-col>
                <b-col cols="12" md="6">
                  <!-- cihaz -->
                  <DoughnutChartGenerate :data="cihazlar" />
                </b-col>
              </b-row>
            </b-card-text>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card no-body>
          <b-card-header class="bg-transparent">
            <h5>En Son Gelen Rezervasyonlar</h5>
          </b-card-header>
          <b-card-text>
            <SonGelenRezervasyonlar />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HomeWidget from '@/components/widget/HomeWidget.vue';
import FirmaCard from '@/components/cards/FirmaCard.vue';
import SonGelenRezervasyonlar from './SonGelenRezervasyonlar.vue';
import DoughnutChartGenerate from '@/components/chart/DoughnutChartGenerate.vue';
import store from '@/store';
import { ref, defineComponent, onMounted } from '@vue/composition-api';
export default defineComponent({
  components: {
    HomeWidget,
    FirmaCard,
    DoughnutChartGenerate,
    SonGelenRezervasyonlar,
  },
  setup() {
    const expo = {};
    expo.show = ref(false);
    expo.homeCardsValue = ref([
      {
        title: 'Toplam Üyeler',
        value: 0,
        icon: 'fad fa-user',
        color: 'green',
        type: 'number',
      },
      {
        title: 'Toplam Acenteler',
        value: {
          aktif: 0,
          pasif: 0,
        },
        icon: 'fad fa-box-full',
        color: 'red',
        type: 'data',
      },
      {
        title: 'Toplam Ziyaret',
        value: 0,
        icon: 'fad fa-people-carry',
        color: 'orange',
        type: 'number',
      },
      {
        title: 'Toplam Ürünler',
        value: 0,
        icon: 'fad fa-box-full',
        color: 'red',
        type: 'number',
      },
      {
        title: 'Toplam Rezervasyon',
        value: {
          _id: 'TRY',
          toplam: 0,
          count: 0,
        },
        icon: 'fad fa-file-chart-line',
        color: 'purple',
        type: 'price',
      },
      {
        title: 'Tamamlanan Rezervasyon',
        value: {
          _id: 'TRY',
          toplam: 0,
          count: 0,
        },
        icon: 'fad fa-money-bill-wave',
        color: 'blue',
        chart: true,
        type: 'price',
      },
      {
        title: 'Yorumlar',
        value: {
          aktif: 0,
          pasif: 0,
        },
        icon: 'fad fa-box-full',
        color: 'orange',
        type: 'data',
      },
      {
        title: 'Destek Hattı',
        value: {
          aktif: 0,
          pasif: 0,
        },
        icon: 'fad fa-box-full',
        color: 'green',
        type: 'data',
      },
    ]);

    expo.tarayicilar = ref({});
    expo.cihazlar = ref({});
    expo.firmaBilgisi = ref({});

    onMounted(async () => {
      expo.show.value = true;
      await store.dispatch('dashboardAnaliz').then((res) => {
        if (res.data.success) {
          const data = res.data.result;
          expo.homeCardsValue.value[0].value = data.uyeler;
          expo.homeCardsValue.value[1].value = data.acenteler[0];
          expo.homeCardsValue.value[2].value = data.analiz;
          expo.homeCardsValue.value[3].value = data.urunler;
          expo.homeCardsValue.value[4].value = data.rezervasyonlar;

          if (data.rezervasyonlar.length) {
            expo.homeCardsValue.value[4].value._id = data.rezervasyonlar[0]._id;
            expo.homeCardsValue.value[4].value.toplam = data.rezervasyonlar[0].toplam;
            expo.homeCardsValue.value[4].value.count = data.rezervasyonlar[0].count;
          }
          if (data.satislar.length) {
            expo.homeCardsValue.value[5].value._id = data.satislar[0]._id;
            expo.homeCardsValue.value[5].value.toplam = data.satislar[0].toplam;
            expo.homeCardsValue.value[5].value.count = data.satislar[0].count;
          }

          expo.homeCardsValue.value[6].value = data.yorumlar[0];
          expo.homeCardsValue.value[7].value = data.destekler[0];
          expo.tarayicilar.value = data.tarayici;
          expo.cihazlar.value = data.cihaz;
          expo.show.value = false;
        }
      });
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
