<template>
  <div>
    <b-alert v-if="!rezervasyonlar.length" show variant="warning" class="text-center m-2">
      <h5>Bilgi</h5>
      <hr />
      <p>Gösterilecek veri yok</p>
    </b-alert>
    <vue-good-table
      v-else
      :columns="columns"
      :rows="rezervasyonlar"
      :line-numbers="false"
      :pagination-options="{
        enabled: false,
        perPage: 100,
        mode: 'pages',
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionText: 'Satır Secildi.',
        clearSelectionText: 'Temizle',
      }"
      :search-options="{ enabled: false }"
      styleClass="vgt-table striped bordered"
    >
      <div slot="selected-row-actions">
        <b-button squared variant="danger" v-b-tooltip.hover="'Toplu Sil'" @click="handlerTopluSil">
          <i class="fad fa-trash" />
        </b-button>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'k_no'">
          <router-link
            :to="{
              name: 'siparis-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="font-weight-bold d-block">
              {{ props.row.k_no }}
            </span>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'tarih'">
          <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 14px">
            {{ props.row.tarih | momentFull }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'uye'" class="d-flex flex-column">
          <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 14px">
            {{ props.row.uye_ad }} {{ props.row.uye_soyad }}
          </b-badge>
          <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
            {{ props.row.uye_e_mail }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'fiyat'">
          <h5>
            <b-badge variant="success" class="rounded-0 font-weight-light d-block text-right">
              {{ props.row.rezervasyon_toplam.genel_toplam | cur }} {{ props.row.kur_turu }}
            </b-badge>
          </h5>
          <h5 v-if="props.row.kur_turu != props.row.secilen_kur">
            <b-badge variant="info" class="rounded-0 font-weight-light d-block text-right">
              {{ props.row.kur_rezervasyon_toplam.genel_toplam | cur }} {{ props.row.secilen_kur }}
            </b-badge>
          </h5>
        </span>

        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button
              class="rounded-0"
              variant="info"
              v-b-tooltip.hover.top="'Siparis Detay'"
              :to="{
                name: 'rezervasyon-detay',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-eye" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { VueGoodTable } from 'vue-good-table';
import { ref, computed, defineComponent } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: {
    VueGoodTable,
  },
  setup(_, context) {
    const expo = {};

    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.columns = ref([
      {
        label: 'Rez. Numarası',
        field: 'k_no',
      },
      {
        label: 'Tarih',
        field: 'tarih',
      },
      {
        label: 'Uye',
        field: 'uye',
      },
      {
        label: 'Fiyat',
        field: 'fiyat',
        width: '12%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: '',
        field: 'action',
        width: '10%',
      },
    ]);

    expo.rezervasyonlar = ref([]);

    expo.handlerFetchAllData = async () => {
      await store.dispatch('dashboardSonGelenRezervansyonlar', { limit: 5 }).then((res) => {
        expo.rezervasyonlar.value = res.data.data;
      });
    };
    expo.handlerFetchAllData();

    expo.selectionChanged = (event) => {
      expo.multiSelectRow.value = event.selectedRows;
    };

    expo.handlerTopluSil = () => {};

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('eTicSiparisSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerPageSizeChange = (event) => {
      expo.filter.value.pageSize = event;
      expo.handlerFetchAllData();
    };

    expo.handlerPageChange = (event) => {
      expo.filter.value.page = event;
      expo.filter.value.page = expo.filter.value.page - 1;
      expo.handlerFetchAllData();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
