<template>
  <b-card no-body class="int-home-card brown">
    <!-- {{ firma }} -->
    <b-overlay :show="show" variant="primary" spinner-type="grow" spinner-variant="primary" :opacity="0" blur="4px" rounded="sm">
      <i class="fad fa-building int-home-card-trend" />
      <div class="int-home-card-head" v-if="Object.keys(firma).length">
        <h4 class="int-home-card-meta">
          <h6 class="font-weight-bold">{{ firma.firma_adi }}</h6>
          <h6>{{ firma.domain }}</h6>
          <span> Kayıt Tarihi : {{ firma.baslama_tarih | moment }}</span>
        </h4>
        <i class="fad fa-building int-home-card-head-icon" />
      </div>
      <div class="int-home-card-foot">
        <div class="int-home-card-compare">Lisans Bitiş Tarihi : {{ firma.bitis_tarih | moment }}</div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import store from '@/store';
import { computed, defineComponent } from '@vue/composition-api';
import LineChartGenerate from '../chart/LineChartGenerate.vue';

export default defineComponent({
  components: { LineChartGenerate },
  props: {
    show: [Boolean],
  },
  setup() {
    const expo = {};
    expo.firma = computed(() => store.getters.getUserFirma);
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.int-home-card {
  position: relative;
  border-radius: 8px;
  padding: 22px;
  border: 0px;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  &:hover {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
    -moz-box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
    box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
    transition: all 0.2s ease-in-out;
  }
  &.brown {
    background: linear-gradient(to left, #a77979, #553939);
    > .b-overlay-wrap .int-home-card-trend {
      color: #553939;
    }
    > .b-overlay-wrap .int-home-card-head .int-home-card-head-icon {
      background: linear-gradient(#a77979, #553939);
    }
  }
  .b-overlay-wrap {
    .int-home-card-trend {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      font-size: 140px;
      opacity: 1;
      z-index: 0;
    }
    .int-home-card-head {
      display: flex;
      align-items: start;
      justify-content: space-between;
      gap: 0px;
      margin-bottom: 40px;
      .int-home-card-meta {
        color: #fff;
        font-weight: 600;
        position: relative;
        z-index: 1;
        span {
          margin-bottom: 7px;
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
          display: block;
        }
      }
      .int-home-card-head-icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 8px;
        text-align: center;
        font-size: 28px;
        color: rgba(255, 255, 255, 0.67);
      }
    }
    .int-home-card-chart {
      z-index: 10;
      padding: 10px 0px;
    }
    .int-home-card-foot {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0px;
      .int-home-card-compare {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 0px 5px;
        color: #fff;
      }
    }
  }
}
</style>
